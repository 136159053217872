<template>
    <main class="notifications">
        <Navbar class="navbar" />
        <div class="notifications-layout">
            <section class="notifications-data">
                <header>
                    <h3>Notifications</h3>
                </header>
                <div v-if="loading" class="loader" >
					<div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
                <div v-else-if="!loading && error !== ''" class="notifications-data__error">
                    {{ error }}
                </div>
                <div v-else-if="!loading && notifications.length === 0 && error === ''" class="notifications-data__empty">
                    No notifications.
                </div>
                <div v-else class="notifications-data__list">
                    <div v-for="notification in notifications"
                        class="notifications-data__list--item"
                        :class="{ new: !notification.seen }"
                        :key="notification.id"
                        @click="onOpenNotification(notification)">
                        <span class="notifications-data__list--item--svgContainer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                            </svg>
                        </span>
                        <!-- <img src="@/assets/icons/bell.svg" alt="Bell icon"> -->
                        <h1>{{ name(notification) }}</h1>
                        <h2>{{ text(notification) }}</h2>
                        <h3>{{ formatTime(notification) }}</h3>
                    </div>
                </div>
            </section>
        </div>
    </main>
</template>
<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Navbar from "@/components/Navbar";

export default {
    name: "Notifications",
    components: { Navbar },
    data() {
        return {
            error: '',
            loading: true,
            notifications: [],
            display: 10 // Number of notification displayed on page 
        }
    },
    mounted() {
        this.getNotifications();
    },
    methods: {
        name(notification) {
            const data = JSON.parse(notification.data)
            return data.username !== undefined
                ? data.username
                : "";
        },
        text(notification) {
            const data = JSON.parse(notification.data)
            switch (data.type) {
                case "comment": {
                    return "commented on your post.";
                }
                case "follower": {
                    return "started following you.";
                }
                case "group_post": {
                    return "New group post.";
                }
                case "group_comment": {
                    return "New comment on group post.";
                }
                default: {
                    return notification.text;
                }
            }
        },
        formatTime(notification) {
            return this.$formatter.formatTimeSince(notification.date)
        },
        async onOpenNotification(notification) {
            const data = JSON.parse(notification.data)
            switch (data.type) {
                case "comment": {
                    this.$router.push({ path: "/post/" + encodeURIComponent(data.id_post) });
                    break;
                }
                case "follower": {
                    this.$router.push({ path: `/user/${encodeURIComponent(data.username)}-${data.hash_email}` });
                    break;
                }
                case "group_post": {
                    this.$router.push({ path: "/post/" + encodeURIComponent(data.id_post) });
                    break;
                }
                case "group_comment": {
                    this.$router.push({ path: "/post/" + encodeURIComponent(data.id_post) });
                    break;
                }
                case "event": {
                    this.$router.push({ path: "/group/" + encodeURIComponent(data.id_group) + "/tab/" + encodeURIComponent(data.id_tab) })
                    break;
                }
                default: {
                    break;
                }
            }
            await this.$api.notification.setSeen(notification.id);
        },
        async getNotifications() {
            this.loading = true;
            const response = await this.$api.getNotifications(this.display, this.userStore.accessToken)
            if(response.success) {
                this.notifications = response.notifications;
                this.error = ''
            } else {
                this.error = response.error.message
            }

            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
    computed: {
        ...mapStores(useUserStore)
    }
}
</script>

<style lang="scss" scoped>
    .notifications { 
        &-layout {
            display: grid;
            grid-template-columns: .5fr 1fr .5fr;
            grid-template-rows: repeat(1, auto);
            justify-items: center;
            margin-top: 20px;
            grid-template-areas: 
                ". notifications .";
            color: #fff;

            .notifications-data {
                grid-area: notifications;
                max-width: 600px;
                background-color: var(--dark);            
				border-radius: 15px;
                @include side-component;
				@include scrollbars($radius: 10px, $size: 15px);
				padding: 10px;
  				text-align: center;
				overflow-y: auto;

                header h3 {
					@include side-component-title;
					margin-bottom: 15px;
				}

                &__empty,
                &__error {
					font-size: 1.2rem;
					margin: 20px 0px;
				}

                &__list {
                    display: flex;
                    flex-direction: column;
                    &--item {
                        @extend .pointer;
                        position: relative;
                        display: grid;
                        grid-template-columns: 50px auto;
                        grid-template-rows: auto auto auto;
                        gap: 0px 0px;
                        grid-template-areas:
                            "image name"
                            "image text"
                            "image date";

                        padding: 3px 10px;
                        margin: 5px 5px;
                        border-radius: 10px;
                        color: var(--text);
                        background-color: var(--medium);

                        h1, h2, h3 {
                            text-align: left;
                            margin: 0;
                        }

                        &--svgContainer {
                            margin-top: calc(50% - 17px);
                            grid-area: image;
                            width: 30px;
                            height: 30px;
                        }

                        h1 {
                            grid-area: name;
                            font-size: 1em;
                        }
                        h2 {
                            grid-area: text;
                            font-size: 0.7em;
                        }
                        h3 {
                            grid-area: date;
                            font-size: 0.7em;
                        }

                        &.new {
                            color: var(--dark);
                            background-color: var(--orange);
                        }
                    }
                }
            }
        }
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;

        div {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;

            div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: 64px;
                height: 64px;
                margin: 8px;
                border: 8px solid white;
                border-radius: 50%;
                animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-color: white transparent transparent transparent;

                &:nth-child(1) {
                animation-delay: -0.45s;
                }

                &:nth-child(2) {
                animation-delay: -0.3s;
                }

                &:nth-child(3) {
                animation-delay: -0.15s;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        .notifications {
            &-layout {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(1, auto);
                grid-template-areas: 
                    "notifications";
                margin: 20px;
                color: #fff;
            }
        }
    }
</style>